<template>
    <div>
        <v-card :elevation="cardElevation">
            <v-toolbar :elevation="toolBarElevation" :dense="isDense">
                <v-toolbar-title v-if="!hideTitle">
                    <v-icon class="mr-2"> {{ titleIcon }}</v-icon>
                    {{ title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field label="Search" single-line hide-details prepend-icon="mdi-magnify" class="mr-4"
                    style="max-width: 300px" v-model="search">
                </v-text-field>
                <v-btn :small="isPageBtnSmall" class="mr-2" @click="paginatePrevious" :disabled="pageNumber == 1">
                    <v-icon> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn :small="isPageBtnSmall" @click="paginateNext" :disabled="
                    pageNumber * pageSize > totalCount - ((totalCount - 1) % pageSize)
                ">
                    <v-icon> mdi-chevron-right </v-icon>
                </v-btn>
            </v-toolbar>
            <v-list>
                <v-list-item v-if="items.length === 0">
                    <v-list-item-content>
                        <v-list-item-title> No {{ title }} </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="(item, index) in items" :key="item.id" :two-line="twoline">
                    <slot name="repeaterItem" :item="item">

                    </slot>
                </v-list-item>
                <!-- <v-list-item-content>
              <v-list-item-title>
                
              </v-list-item-title>
              <v-list-item-subtitle>
                
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-group> -->
            </v-list>
        </v-card>
    </div>
</template>
  
<script>
export default {
    props: {
        isDense: {
            type: Boolean,
            default: false
        },
        hideTitle: {
            type: Boolean,
            default: false
        },
        isPageBtnSmall: {
            type: Boolean,
            default: false
        },
        toolBarElevation: {
            type: Number,
            default: 6
        },
        cardElevation: {
            type: Number,
            default: 2
        },
        titleIcon: {
            type: String,
            default: "mdi-rocket",
        },
        title: {
            type: String,
            default: "Selected Permission Groups",
        },
        courier: {
            type: Object,
            default: () => { }
        },
        pageSize: {
            type: Number,
            default: 5
        },
        twoline: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
    },
    watch: {
        search(newValue, oldValue) {
            this.pageNumber = 1;
            this.getData();
        },
    },
    created () {
        this.getData();
    },
    methods: {
        getData() {
            this.courier.getData(this.pageNumber, this.pageSize, this.search).then(payload => {
                if (payload && payload.payload) {
                    this.items = payload.payload;
                }
            })
        },
        paginateNext() {
            this.pageNumber++;
        },
        paginatePrevious() {
            this.pageNumber--;
        },
    },
    data() {
        return {
            items: [],
            search: "",
            pageNumber: 1,
            totalCount: 0,
        };
    },
};
</script>
  
<style></style>