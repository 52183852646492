<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card>
                    <v-toolbar>
                        <v-toolbar-title>
                            <v-icon class="mr-2">
                                mdi-account-multiple-outline
                            </v-icon>
                            Group: {{ group.name }}
                        </v-toolbar-title>
                    </v-toolbar>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <trev-cool-table ref="scopeTable" :courier="$courier.ScopeGroups.withId($route.params.scopeGroupId).Scopes"
                    :headers="[{ text: 'Scope', value: 'scope' }, { text: 'Actions', value: 'actions' }]">
                    <template #title>
                        <v-icon class="mr-2">
                            mdi-folder-key-outline
                        </v-icon>
                        Scopes
                    </template>
                    <template #item.actions="{ item }">
                        <v-btn color="danger" small @click="isShowingDeleteScope = true; scopeToDelete = item;">
                            <v-icon class="mr-2">
                                mdi-delete
                            </v-icon>
                            Delete
                        </v-btn>
                    </template>
                </trev-cool-table>
            </v-col>
            <v-col>
                <v-card>
                    <v-toolbar>
                        <v-toolbar-title>
                            <v-icon class="mr-2">
                                mdi-key-plus
                            </v-icon>
                            Add Scopes
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-select multiple @keydown.enter.native.prevent="saveScopeOntoGroup"
                            v-model="selectedNewScope" item-value="id"
                            item-text="scope" placeholder="Start typing to search" label="Select Scope"
                            :loading="isLoadingScopeSelect" :items="selectScopes" return-object>
                        </v-select>
                        <v-btn color="primary" small @click="saveScopeOntoGroup">
                            <v-icon class="mr-2">
                                mdi-save
                            </v-icon>
                            Save Scope onto this Group
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <trev-confirm-delete-dialog :itemToDelete="scopeToDelete.scope" :show.sync="isShowingDeleteScope"
            @delete="deleteScope" @cancel="cancelDeleteScope" maxWidth="60vh">
        </trev-confirm-delete-dialog>
    </v-container>
</template>

<script>
import TrevConfirmDeleteDialog from '../../../../components/base/dialog/trev-confirm-delete-dialog.vue';
import trevCoolTable from '../../../../components/tables/trev-cool-table.vue';
import TrevEvenCoolerList from '../../../../components/tables/trev-even-cooler-list.vue';
export default {
    components: { trevCoolTable, TrevConfirmDeleteDialog, TrevEvenCoolerList },
    watch: {
        selectScopeSearch(newValue, oldValue) {
            this.getScopesForSelect();
        }
    },
    created() {
        this.$courier.ScopeGroups.getById(this.$route.params.scopeGroupId).then(scopeGroup => {
            this.group = scopeGroup;
        });

        this.getGroupScopes();

        this.getScopesForSelect();
    },
    methods: {
        async getGroupScopes() {
            return this.$courier.ScopeGroups.withId(this.$route.params.scopeGroupId).Scopes.getData(1, 99999, '').then(scopes => {
                if (scopes && scopes.payload) {
                    this.groupScopes = scopes.payload;
                }
            });
        },
        async saveScopeOntoGroup() {
            if (this.selectedNewScope && this.selectedNewScope.length) {
                for (const newScope of this.selectedNewScope) {
                    await this.$courier.ScopeGroups.withId(this.group.id).Scopes.updateById(newScope.id, null, false);
                }

                this.$refs.scopeTable.getData();
                this.getScopesForSelect();
                this.selectedNewScope = {};
                this.selectScopeSearch = '';
            }
        },
        async getScopesForSelect() {

            await this.getGroupScopes();

            this.$courier.Scopes.getData(1, 99999, this.selectScopeSearch).then(scopes => {
                if (scopes && scopes.payload) {
                    this.selectScopes = scopes.payload.filter(x => !this.groupScopes.some(s => s.id == x.id));
                }
            })
        },
        deleteScope() {
            this.$courier.ScopeGroups.withId(this.group.id).Scopes.removeById(this.scopeToDelete.id).then(() => {
                this.$refs.scopeTable.getData();
                this.getScopesForSelect();
            })
        },
        cancelDeleteScope() {
            this.scopeToDelete = {};
            this.isShowingDeleteScope = false;
        }
    },
    data() {
        return {
            selectScopeSearch: '',
            selectScopes: [],
            selectedNewScope: [],
            isLoadingScopeSelect: false,
            isShowingDeleteScope: false,
            scopeToDelete: {},
            group: {},
            groupScopes: [],
        }
    },
}
</script>

<style></style>